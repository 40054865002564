import {enterpriseRole} from '../config/reactConfig';
import _ from 'lodash';
import {load} from 'cheerio';

const isValidUrl = url => {
  // Check if the URL is a valid URL
  try {
    if (!url.startsWith('https://') && !url.startsWith('http://')) {
      return false;
    }
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

const inlineToClass = (html, nonceId) => {
  const htmlCode = html ? html : '<div></div>';
  const $ = load(htmlCode);
  const styleMap = new Map();
  $('*').each((i, val) => {
    const inlineStyle = $(val).attr('style');
    if (inlineStyle) {
      const existingClassName = styleMap.get(inlineStyle);
      if (existingClassName) {
        $(val).addClass(existingClassName);
      } else {
        const className = `class-${i}`;
        styleMap.set(inlineStyle, className);
        $(val).addClass(className);
      }
      $(val).removeAttr('style');
    }
  });
  const styleRules = Array.from(styleMap.entries()).map(
    ([style, className]) => `.${className} { ${style} }`
  );
  const deduplicatedStyleRules = _.uniq(styleRules);
  const styleTag = `<style nonce='${nonceId}'>${deduplicatedStyleRules.join('\n')}</style>`;
  $('body').append(styleTag);
  return $.html();
};

const cssVar = name => {
  // name = String(name);
  if (name.substr(0, 2) !== '--') {
    name = '--' + name;
  }
  return getComputedStyle(document.documentElement).getPropertyValue(name);
};

const validateDomainValue = domain => {
  if (domain && domain?.indexOf('www.') === -1) {
    const re = /^\w+([.-]?\w+)*(\.\w{2,3})+$/i;
    return re.test(domain);
  } else {
    return false;
  }
};

const containsOnlySpecialCharacter = str => {
  if (str) {
    const re = /^[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~\d]*$/g;
    return re.test(str?.trim());
  } else {
    return false;
  }
};

const containsEmoji = str => {
  if (str) {
    const re = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
    return re.test(str?.trim());
  } else {
    return false;
  }
};

const containsOnlyNumber = str => {
  if (str) {
    const re = /^\d+$/;
    return re.test(str?.trim());
  } else {
    return false;
  }
};

const getFirstNameFromFull = name => {
  const splitName = name?.split(',');
  if ( splitName?.length > 1) {
    return splitName[1].trim();
  } else {
    return '';
  }
};

const getLastNameFromFull = name => {
  const splitName = name?.split(',');
  if ( splitName?.length > 0) {
    return splitName[0].trim();
  } else {
    return '';
  }
};

const roleOptions = [
  // eslint-disable-next-line max-len
  {name: 'Trusted Partner Admin', role: enterpriseRole.TRUSTED_PARTNER_ADMIN, description: 'Admin can add new users and can view documentation.'},
  // eslint-disable-next-line max-len
  {name: 'Trusted Partner Member', role: enterpriseRole.TRUSTED_PARTNER_MEMBER, description: 'Member can view documentation.'},
  {name: 'Trusted Partner Viewer', role: enterpriseRole.TRUSTED_PARTNER_VIEWER, description: 'Viewer can view documentation.'}
];

export {
  inlineToClass,
  validateDomainValue, 
  containsOnlySpecialCharacter, 
  containsEmoji, 
  containsOnlyNumber, 
  getFirstNameFromFull, 
  getLastNameFromFull,
  cssVar,
  isValidUrl,
  roleOptions
};
