/* eslint-disable max-len */
import {axiosPrivate} from '../interceptors';
import getBaseURL from './getBaseURL';

const baseURl = getBaseURL();

export const getGitDocuments = (projectId, filePath, branch, classification) => {
  const url = `/devportal/v3/documents/git/${projectId}?file_path=${filePath}&classification=${classification}`;
  return axiosPrivate.request({url: url, method: 'GET'});
};
export const getDocuments = (classification, options = {}) => {
  const url = `/devportal/v3/documents?classification=${classification}`;
  return axiosPrivate.request({url: url, method: 'GET', ...options});
};
export const getDocumentsbyId= (classification, queryString) => {
  const url = `/devportal/v3/documents/${queryString}?classification=${classification}`;
  return axiosPrivate.request({url: url, method: 'GET'});
};
export const deleteDocuments= (queryString) => {
  const url = `/devportal-admin/v3/documents/${queryString}`;
  return axiosPrivate.request({url: url, method: 'DELETE'});
};
export const updateDocuments= (data) => {
  const url = '/devportal-admin/v3/documents';
  return axiosPrivate.request({url: url, method: 'PUT', data});
};
export const createDocuments= (data) => {
  const url = '/devportal-admin/v3/documents';
  return axiosPrivate.request({url: url, method: 'POST', data});
};
export const getUserbyEmail= (email) => {
  const url = `/devportal/v3/users/${email}`;
  return axiosPrivate.request({url: url, method: 'GET'});
};

export const getNews= (filePath='') => {
  const url = `/devportal/v3/public/news${filePath?`?file_path=${filePath}`:''}`;
  return axiosPrivate.request({url: url, method: 'GET'});
};
export const getHelpPage= (docID, filePath='sitemap.json', ref='tmo/main') => {
  const url = `/devportal/v3/public/help?file_path=${filePath}&doc_id=${docID}&ref=${ref}`;
  return axiosPrivate.request({url: url, method: 'GET'});
};
export const getPublicDocs = (classification, file_path='') =>{
  const url = `/devportal/v3/public?classification=${classification}${file_path?`&file_path=${file_path}`:''}`;
  return axiosPrivate.request({url: url, method: 'GET'});
};
export const getDomains= () => {
  const url = '/devportal-admin/v3/users/domains';
  return axiosPrivate.request({url: url, method: 'GET'});
};
export const getRepoUrl= (id, ref) => {
  const url = `/devportal/v3/documents/project/repoUrl/${id}?branch=${ref}`;
  return axiosPrivate.request({url: url, method: 'GET'});
};
export const getExtensions= () => {
  const url = '/devportal/v3/documents/extensions';
  return axiosPrivate.request({url: url, method: 'GET'});
};
export const gitValidation= (id, branch) => {
  const url = `/devportal-admin/v3/documents/project/validate/${id}?branch=${branch}`;
  return axiosPrivate.request({url: url, method: 'GET'});
};

export const getGlobalSearchResults= (title) => {
  const url = `/devportal/v3/documents/search?title=${title}`;
  return axiosPrivate.request({url: url, method: 'GET'});
};

export const validateGitId= (id) => {
  const url = `/devportal-admin/v3/documents/project/gitlab/${id}`;
  return axiosPrivate.request({url: url, method: 'GET'});
};

export const getBase64Image= (data, source) => {
  const url = `/devportal/v3/documents/image/?data=${JSON.stringify(data)}`;
  return axiosPrivate.request({url: url, method: 'GET', cancelToken: source.token});
};

export const getStaticData = async (id, branch, path, classification) => {
  let authorization;
  let xauth;
  let url;
  let reqHeaders = {};
  if (localStorage.getItem('token-storage')) {
    const {idToken} = JSON.parse(localStorage.getItem('token-storage'));
    authorization = `Bearer ${idToken}`;
    xauth = idToken;
    reqHeaders = {
      'Authorization': authorization,
      'x-auth-originator': xauth
    };
    url = `/devportal/v3/download/${encodeURIComponent(path)}?ref=${encodeURIComponent(branch)}&classification=${classification}&id=${id}`;
  }
  const response = await fetch(url, {
    method: 'GET', headers: reqHeaders // *GET, POST, PUT, DELETE, etc.
  });
  return response;
};


