import styled from 'styled-components';

const Text = styled.div`
  color: ${props => (props.color ? props.color : props.theme.colors?.white)};
  width: ${props => props.width && props.width};
  // &::after{
  //   content:" *";
  //   display: inline-block;
  //   text-align: left;
  //   color: red;
  // }
  display: ${props =>
    props.display ? props.display : props.theme.flexStyles?.flex};
  flex-direction: ${props => props.direction && props.direction};
  text-align: ${props => props.textAlign && props.textAlign};
  align-items: ${props => props.alignItems && props.alignItems};
  margin: ${props => props.margin && props.margin};
  background: ${props => props.background && props.background};
  opacity: ${props => (props.opacity ? props.opacity : '1')};
  min-height: ${props => (props.minHeight ? props.minHeight : '')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '')};
  height: ${props => (props.height ? props.height : '')};
  border-bottom: ${props => (props.borderBottom ? props.borderBottom : '')};
  border-right: ${props => (props.borderRight ? props.borderRight : '')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '')};
  justify-content: ${props => props.justifyContent && props.justifyContent};
  title: ${props => (props.title? props.title : '')};

  text-transform: ${props => props.textTransform && props.textTransform};
  padding: ${props => props.padding && props.padding};
  padding-top: ${props => props.paddingTop && props.paddingTop};
  font-weight: ${props => props.weight ? props.weight : props.theme.weight};
  word-break: ${props => props.wordBreak && props.wordBreak};
  white-space: ${props => props.whiteSpace && props.whiteSpace};
  font-style: ${props => props.fontStyle && props.fontStyle};
  cursor: ${props => props.cursor && props.cursor};
  line-height: ${props => props.lineHeight && props.lineHeight};
  letter-spacing: ${props => props.letterSpacing && props.letterSpacing};

  // Position
  position: ${props => props.position && props.position};
  top: ${props => props.top && props.top};
  bottom: ${props => props.bottom && props.bottom};
  left: ${props => props.left && props.left};
  z-index: ${props => props.zIndex && props.zIndex};

  &:hover, &:focus {
    color: ${props => props.clickable && '#e20074'};
    text-decoration: ${props => props.clickable && 'underline'};
  }

  // Font size
  ${props => {
    const theme = props.theme;
    switch (props.fontSize) {
    case 'bigHeading': // 54px
      return `font-size: ${theme.texts?.bigHeading}`;
    case 'heading': // 40px
      return `font-size: ${theme.texts?.headingText}`;
    case 'subHeading': // 32px
      return `font-size: ${theme.texts?.subHeadingText}`;
    case 'smallText': // 14px  0.875rem
      return `font-size: ${theme.texts?.smallText}`;
    case 'bigSubHeadingText':
      return `font-size: ${theme.texts?.bigSubHeadingText}`;
    case 'subTitle':
      return `font-size: ${theme.texts?.subTitle}`;
    case 'formLabel':
      return `font-size: ${theme.texts?.formLabel}`;
    case 'normal':
      return `font-size: ${theme.texts?.normalText}`;
    case 'font24px':
      return `font-size: ${theme.texts?.font24px}`;
    case 'extraSmallText':
      return `font-size: ${theme.texts?.extraSmallText}`;
    case 'font56px':
      return `font-size: ${theme.texts?.font56px}`;
    case 'font28px':
      return `font-size: ${theme.texts?.font28px}`;
    case 'font20px':
      return `font-size: ${theme.texts?.font20px}`;
    case 'font18px':
      return `font-size: ${theme.texts?.font18px}`;
    case 'font16px':
      return `font-size: ${theme.texts?.font16px}`;
    case 'font12px':
      return `font-size: ${theme.texts?.font12px}`;
    default:
      if (props.fontSize) {
        return `font-size: ${props.fontSize}`;
      } else {
        return `font-size: ${theme.texts?.normalText}`;
      }
    }
  }};

  //Font Color 
  ${(props) => {
    const theme = props.theme;
    switch (props.color) {
    case 'primary': // black  #000
      return `color: ${theme.colors?.primary}`;
    case 'secondary': // white #fff
      return `color: ${theme.colors?.secondary}`;
    case 'third': // magenta  #e20074
      return `color: ${theme.colors?.third}`;
    case 'fourth': // grey shade : disabled text  #979797
      return `color: ${theme.colors?.fourth}`;
    case 'fifth': // darker grey shade   #4b4d61
      return `color: ${theme.colors?.fifth}`;
    case 'sixth': // light grey shade   #cccccc
      return `color: ${theme.colors?.sixth}`;
    case 'error': // #ec1e50
      return `color: ${theme.colors?.error}`;
    default:
      if (props.color) {
        return `color: ${props.color}`;
      } else {
        return `color: ${theme.colors?.secondary}`;
      }
    }
  }};

  //Font Family
  ${props => {
    const theme = props.theme;
    switch (props.family) {
    case 'primary':
      return `font-family: ${theme.fonts?.primary}`;
    case 'secondary':
      return `font-family: ${theme.fonts?.secondary}`;
    case 'third':
      return `font-family: ${theme.fonts?.third}`;
    case 'main':
      return `font-family: ${theme.fonts?.main}`;
    default:
      if (props.family) {
        return `font-family: ${props.family}`;
      }
    }
  }}
`;

export default Text;
