import React, {useState, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {StyledHeader, StyledNavButton, useStyles, StyledDivContainer, StyledHeaderLogo, 
  AccountButton, User, Roles, Name, StyledTag, AllRoles, StyledHeaderContainer} from './style';
import DevPortalHeaderLogo from '../../assets/images/devportal_header_logo.svg';
import * as theme from '../../config/theme';
import MenuListComposition from '../LandingDocument/action';
import Search from './components/search';
import ApiRef from './components/api-reference';
import Default from '../../assets/images/Default_Pic.svg';
import {
  StyledDiv,
  StyledMaxWidthSection,
  StyledButton,
} from './../../style';
import {UserConfig} from '../../actions/userConfigActon';
import {ImpersonationLoaderConfig} from '../../actions/impersonationLoaderConfig';
import {SignOut, handleLogin} from '../signIn/auth';
import Alerts from '../utils/userAlert';
import {editUser} from '../../api/services/users';
import ImpersonationDialog from '../accessControl/usersTab/ImpersonationDialog';
import {openInNewTab} from '../shared/utils/openInNewTab';
import {featureFlags} from '../../config/reactConfig';
import {cssVar} from '../../utils/utils';
import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import Banner from '../homepage/banner';
const {ErrorAlert, CustomErrorAlert} = Alerts;

const ImpersonationIndicator = styled.div`
  height: 4.063rem;
  width: 100%;
  background-color: rgba(243, 220, 232, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImpersonationExitButton = styled.button`
  margin: 0 0 0 1.5rem;
  padding: 0.5rem 1.25rem;
  border: solid 1px ${()=> cssVar('--tmo-magenta')};
  background-color: #fff;
  color: ${()=> cssVar('--tmo-magenta')};
  cursor: pointer;
`;


const Header = props => {
  const {userConfig, loaderConfig, changeImpersonationLoaderConfig, featureFlag, globalConfig} = props;
  const classes = useStyles();
  const {instance} = useMsal();
  const [userOpen, setUserOpen] = useState(false);
  const [activePage, setActivePage] = useState('');
  const isLoggedIn = useIsAuthenticated();
  const [showAllroles, setShowAllroles] = useState(false);
  const [isTestUser, setIsTestUser] = useState(false);
  const [logoEnable, setLogoEnable] = useState(window.location.pathname==='/');
  const [maxWidth, setMaxWidth] = useState(theme.default.maxWidth);
  const [openImpersonateModal, setOpenImpersonateModal] = useState(false);
  const categoryRef = useRef(null);
  const roleObject = {
    'admin': 'Admin',
    'trusted_sdk_manager': 'Trusted SDK',
    'trusted_domain_manager': 'Trusted Domain',
    'trusted_author': 'Trusted Author',
    'trusted_partner': 'Trusted Partner',
    'trusted_partner_manager': 'Trusted Partner Manager',
    'internal_user': 'Internal User',
    'trusted_partner_admin': 'Trusted Partner Admin',
    'trusted_partner_member': 'Trusted Partner Member',
    'trusted_partner_viewer': 'Trusted Partner Viewer',
  };

  const history = useHistory();
  const {location: {pathname}} = history;
  const login = async (e) => {
    handleLogin('redirect', instance);
  };
  useEffect(() => {
    // setisLoggedIn(Object.keys(userConfig).length > 0);
    if ('user_id' in userConfig) {
      if (userConfig.test) {
        setIsTestUser(true);
      } else {
        setIsTestUser(userConfig.role?.length===1 && userConfig?.role.includes('trusted_partner')); 
      }
    }
  }, [userConfig]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (categoryRef.current && !categoryRef.current.contains(event.target)) {
        setUserOpen(false);
      }
    }
    
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [categoryRef]);

  useEffect(() => {
    history.listen((location) => {
      if (location.pathname === '/') {
        setLogoEnable(true);
      } else {
        setLogoEnable(false);
      }
      if (location.pathname === '/terms-of-use' || location.pathname === '/data-use-policy') {
        setActivePage('');
      }
      if (location.pathname.includes('sdk')) {
        setActivePage('sdk');
      } else if (location.pathname.includes('documents')) {
        setActivePage('documents');
      } else if (location.pathname.includes('domain')) {
        setActivePage('domain');
      } else if (location.pathname.includes('reference/')) {
        setActivePage('reference');
      } else if (location.pathname.includes('news') && !location.pathname.includes('news_management')) {
        setActivePage('news');
      } else if (location.pathname.includes('contactUs')) {
        setActivePage('contactUs');
      } else if (location.pathname.includes('help')) {
        setActivePage('help');
      } else {
        setActivePage('');
      }
    });
  }, [history]);

  useEffect(() => {
    const tab = window.location.pathname;
    if (tab.includes('sdk')) {
      setActivePage('sdk');
    } else if (tab.includes('documents')) {
      setActivePage('documents');
    } else if (tab.includes('domain')) {
      setActivePage('domain');
    } else if (tab.includes('reference/')) {
      setActivePage('reference');
    } else if (tab.includes('news') && !tab.includes('news_management')) {
      setActivePage('news');
    } else if (tab.includes('contactUs')) {
      setActivePage('contactUs');
    } else if (tab.includes('help')) {
      setActivePage('help');
    } else {
      setActivePage('');
    }
  }, [pathname]);


  const pushToPage = (page) => {
    setActivePage(page);
    history.push('/' + page);
    localStorage.removeItem('alignment');
  };

  const logoClicked = () => {
    if (history.location.pathname !== '/') {
      setActivePage('');
      history.push('/');
    }
  };
  
  const handleSignOut = ()=>{
    SignOut(instance);
  };

  const toggleImpersonationDialog = () => {
    setOpenImpersonateModal(true);
  };

  const closeImpersonateDialog = () => {
    setOpenImpersonateModal(false);
  };

  const StopImpersonation = async () => {
    closeImpersonateDialog();
    changeImpersonationLoaderConfig({loader: true, message: 'Taking you back to your dashboard'});
    if (userConfig?.impersonator) {
      const payLoad = {impersonation: ''};
      try {
        const stopImpersonation = await editUser(payLoad);
        if (stopImpersonation) {
          changeImpersonationLoaderConfig({loader: true, 
            user: true, message: 'Taking you back to your dashboard', path: '/access_control'});
        }
      } catch (err) {
        changeImpersonationLoaderConfig({loader: false, user: false, message: ''});
        err.response&&err.response?.data?.message ? CustomErrorAlert(err.response.data.message) : ErrorAlert(err);
      }
    }
  };

  const getMaxWidth = () => {
    const max = theme.default.maxWidth();
    if (max !== maxWidth) {
      setMaxWidth(max);
    }
  };
  window.addEventListener('resize', getMaxWidth);
  return (
    <>
      <StyledHeader className='header-navv'>
        {userConfig?.impersonator && 
    <>
      <ImpersonationIndicator>
        Viewing as {userConfig?.name}
        <ImpersonationExitButton onClick={toggleImpersonationDialog} aria-label="Exit Impersonation View" tabIndex={0}>
          Exit View
        </ImpersonationExitButton>
      </ImpersonationIndicator>
      <ImpersonationDialog openImpersonateModal={openImpersonateModal} onClose={closeImpersonateDialog}
        handleImpersonate={StopImpersonation} impersonationStatus={'stopImpersonation'}/>
    </>}
        <StyledMaxWidthSection maxWidth={maxWidth}>
          <StyledDivContainer className="d-flex align-items-center justify-content-between">
            <StyledHeaderContainer className='icon-button' disabled={logoEnable} 
              onClick={() => logoClicked()} aria-label="Home" tabIndex={0}>
              <StyledHeaderLogo src={DevPortalHeaderLogo} alt="DevPortal Home" />
            </StyledHeaderContainer>
            <StyledDiv role='navigation' aria-label="Main Navigation" className="d-flex align-items-center justify-content-start">
              {isLoggedIn && !loaderConfig && <StyledNavButton className={activePage === 'documents' ? classes.active : ''} 
                onClick={(e) => {
                  if (e.ctrlKey) {
                    openInNewTab('/documents', true);
                  } else {
                    pushToPage('documents');
                  }
                }}
                onKeyDown={(e) => e.key === 'Enter' && pushToPage('documents')}
                tabIndex={0}
                aria-label="Documents"
              >DOCUMENTS</StyledNavButton>}
              {isLoggedIn && !loaderConfig && <StyledNavButton className={activePage === 'sdk' ? classes.active : ''} onClick={(e) => {
                if (e.ctrlKey) {
                  openInNewTab('/sdk', true);
                } else {
                  pushToPage('sdk');
                }
              }}
              onKeyDown={(e) => e.key === 'Enter' && pushToPage('sdk')}
              tabIndex={0}
              aria-label="SDKs">SDKS</StyledNavButton>}
              {isLoggedIn && !loaderConfig && <ApiRef activePage={activePage} 
                setActivePage={setActivePage} pushToPage={pushToPage} userConfig={userConfig}/>}
              {isLoggedIn && !loaderConfig && <StyledNavButton to="/news"
                className={activePage === 'news' ? classes.active : ''} onClick={(e) => {
                  if (e.ctrlKey) {
                    openInNewTab('/news', true);
                  } else {
                    pushToPage('news');
                  }
                }}
                onKeyDown={(e) => e.key === 'Enter' && pushToPage('news')}
                tabIndex={0}
                aria-label="News">NEWS</StyledNavButton>}
              {isLoggedIn && !loaderConfig ? (userConfig?.role?.length===1 && userConfig.role.includes('trusted_partner')) ?
                null:
                <StyledNavButton to="/help"
                  className={activePage === 'help' ? classes.active : ''} onClick={(e) => {
                    if (e.ctrlKey) {
                      openInNewTab('/help?sectionName=Publish_API', true);
                    } else {
                      pushToPage('help?sectionName=Publish_API');
                    }
                  }}
                  onKeyDown={(e) => e.key === 'Enter' && pushToPage('help?sectionName=Publish_API')}
                  tabIndex={0}
                  aria-label="Help">HELP</StyledNavButton>:null}
              {isLoggedIn && !loaderConfig && <Search pushToPage={pushToPage} setActivePage={setActivePage}/>}
              {isLoggedIn && !loaderConfig && (
                <AccountButton ref={categoryRef} onClick={(e) => setUserOpen(!userOpen)} 
                  onKeyDown={(e) => e.key === 'Enter' && setUserOpen(!userOpen)} aria-expanded={userOpen} aria-label="User Account" 
                  tabIndex={0}>
                  <img style={{height: '2.5rem'}} src={Default} alt='User' />
                  <User>
                    <Name>{userConfig.name}</Name>
                    <Roles>
                      <StyledTag>{userConfig?.role && userConfig.role?.length > 0 && roleObject[userConfig.role[0] === 'trusted_partner' &&
                  featureFlag[featureFlags.FF_ENABLE_PARTNER_PAGE] && userConfig?.enterprise && userConfig.enterprise?.length > 0 &&
                  userConfig?.enterprise[0] && 
                  userConfig?.buName?.find(bu=> globalConfig.PARTNERS_BU_NAMES.split(',').find((pBUName) => pBUName === bu.name)) &&
                  globalConfig?.ALL_PARTNER_PAGES?.split(',')?.includes('tpu_account_details')?
                        `${userConfig?.enterprise[0].role}` : userConfig.role[0]]}</StyledTag>
                      {userConfig?.role?.length>1 && <span
                        onMouseOver={() => setShowAllroles(true)}
                        onMouseLeave={() => setShowAllroles(false)}
                        aria-label={`User has ${userConfig.role.length - 1} more roles`}
                      >+{userConfig?.role?.length-1}</span>}
                    </Roles>
                  </User>
                  {showAllroles && <AllRoles>
                    <ul>
                      {userConfig.role.map(((v, i) => (i!==0) && <li key={i}>{roleObject[v]}</li>))}
                    </ul>
                  </AllRoles>}
                  <MenuListComposition accounts={true} setActivePage={setActivePage} userOpen={userOpen} setUserOpen={setUserOpen} 
                    isTestUser={isTestUser} handleSignOut={handleSignOut}/>
                </AccountButton>
              )}
              {(loaderConfig || !isLoggedIn ) && <StyledButton className={classes.ctaButton} onClick={(e) => login(e)} 
                aria-label="Login" tabIndex={0}>LOGIN</StyledButton>}
            </StyledDiv>
          </StyledDivContainer>
        </StyledMaxWidthSection>
      </StyledHeader>
      {(loaderConfig || !isLoggedIn ) &&<Banner />}
    </>
  
  );
}
;


const mapStateToProps = ({userConfig, loaderConfig, featureFlag, globalConfig}) => ({
  userConfig,
  loaderConfig,
  featureFlag, 
  globalConfig
});

const mapDispatchToProps = dispatch => ({
  change_user_config: () => dispatch(UserConfig()),
  changeImpersonationLoaderConfig: data => dispatch(ImpersonationLoaderConfig(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
