import React from 'react';
import './banner.css';

const StickyBanner = () => {
  return (
    <div className="sticky-banner">
      <p className="sticky-banner-message">
      Your Developer Portal login credentials need to be reset. 
      Please follow the instructions that have been shared with you to reset your 
      credentials accordingly. Thank you for your cooperation.
      </p>
    </div>
  );
};

export default StickyBanner;
